<ion-header *ngIf="school_user === undefined" class="ion-no-border" [translucent]="true">
  <ion-item>
    <ion-spinner name="dots"></ion-spinner>
  </ion-item>
</ion-header>
<ion-header *ngIf="school_user" class="ion-no-border" [translucent]="true">
    <ion-buttons class="kc-menu-button" slot="start">
        <ion-avatar class="avatar-mobile">
          <ion-menu-button>
            <img src="{{ school_user.profile.avatarversion ? ( staticBaseUrl + '/profiles/' + school_user.profile.id + '/avatars/' + school_user.profile.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/avatar.svg' ) }}" alt="avatar" />
          </ion-menu-button>
        </ion-avatar>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button routerDirection="root" routerLink="/notifications">
        <ion-icon slot="icon-only" name="notifications"></ion-icon>
      </ion-button>
      <ion-badge *ngIf="unreadNotifications">{{ unreadNotifications }}</ion-badge>
    </ion-buttons>
</ion-header>
<ion-header *ngIf="school_user === null" class="ion-no-border" [translucent]="true" class="ion-align-items-center ion-justify-content-center">
  <ion-buttons>
    <ion-button routerDirection="root" routerLink="/login" fill="outline" class="ion-padding-horizontal">
      <ion-icon slot="start" name="lock-closed"></ion-icon> Login
    </ion-button>

    <ion-text>or</ion-text>

    <ion-button routerDirection="root" routerLink="/sign-up" fill="outline" class="ion-padding-horizontal">
      <ion-icon slot="start" name="lock-open"></ion-icon> Sign Up
    </ion-button>
  </ion-buttons>
</ion-header>