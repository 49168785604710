import { Routes } from '@angular/router';
import { NoAuthGuard, AuthGuard } from './core/guards';

export const routes: Routes = [
  {
    path: '',
    canActivate: [NoAuthGuard],
    loadComponent: () => import('./pages/home/home.page').then( m => m.HomePage)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/dashboard/dashboard.page').then( m => m.DashboardPage)
  },
  {
    path: 'login',
    canActivate: [NoAuthGuard],
    loadComponent: () => import('./pages/login/login.page').then( m => m.LoginPage)
  },
  {
    path: 'register',
    canActivate: [NoAuthGuard],
    loadComponent: () => import('./pages/register/register.page').then( m => m.RegisterPage)
  },
  {
    path: 'reset-password',
    canActivate: [NoAuthGuard],
    loadComponent: () => import('./pages/reset-password/reset-password.page').then( m => m.ResetPasswordPage)
  },
  {
    path: 'about',
    loadComponent: () => import('./pages/about/about.page').then( m => m.AboutPage)
  },
  {
    path: 'terms',
    loadComponent: () => import('./pages/terms/terms.page').then( m => m.TermsPage)
  },
  {
    path: 'privacy',
    loadComponent: () => import('./pages/privacy/privacy.page').then( m => m.PrivacyPage)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/profile/profile.page').then( m => m.ProfilePage)
  },
  {
    path: 'profile/know-your-customer',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/profile/know-your-customer/know-your-customer.page').then( m => m.KnowYourCustomerPage)
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/notifications/notifications.page').then( m => m.NotificationsPage)
  },
  {
    path: 'school',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/school/school.page').then( m => m.SchoolPage)
  },
  {
    path: 'school/create',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/school/create/create.page').then( m => m.CreatePage)
  },
  {
    path: 'school/account',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/accounts/accounts.page').then( m => m.AccountsPage)
  },
  {
    path: 'school/transaction',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/transactions/transactions.page').then( m => m.TransactionsPage)
  },
  {
    path: 'school/:school_id',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/school/view/view.page').then( m => m.ViewPage)
  },
  {
    path: 'school/:school_id/know-your-school',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/school/know-your-school/know-your-school.page').then( m => m.KnowYourSchoolPage)
  },
  {
    path: 'school/:school_id/managers',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/school/managers/managers.page').then( m => m.ManagersPage)
  },
  {
    path: 'school/:school_id/transaction',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/school/transaction/transaction.page').then( m => m.TransactionPage)
  },
  {
    path: 'school/:school_id/transaction-code',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/school/transaction-code/transaction-code.page').then( m => m.TransactionCodePage)
  },
  {
    path: 'school/:school_id/account',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/school/account/account.page').then( m => m.AccountPage)
  },
  {
    path: 'school/:school_id/account/create',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/school/account/create/create.page').then( m => m.CreatePage)
  },
  {
    path: 'school/:school_id/account/:account_id',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/school/account/view/view.page').then( m => m.ViewPage)
  },
  {
    path: 'school/:school_id/account/:account_id/deposit',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/school/account/deposit/deposit.page').then( m => m.DepositPage)
  },
  {
    path: 'school/:school_id/account/:account_id/integration',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/school/account/integration/integration.page').then( m => m.IntegrationPage)
  },
  {
    path: 'school/:school_id/account/:account_id/transaction',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/school/account/transaction/transaction.page').then( m => m.TransactionPage)
  },
  {
    path: 'school/:school_id/account/:account_id/withdraw',
    canActivate: [AuthGuard],
    loadComponent: () => import('./pages/school/account/withdraw/withdraw.page').then( m => m.WithdrawPage)
  },
  {
    path: '**',
    loadComponent: () => import('./pages/not-found/not-found.page').then( m => m.NotFoundPage)
  },
];
