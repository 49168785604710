<ion-card>
  <ion-card-header *ngIf="transaction.status === TransactionStatusEnum.Pending">
    <ion-card-subtitle>
      {{ transaction.creation_date | date: 'longDate' }}
    </ion-card-subtitle>
  </ion-card-header>

  <ion-card-content>
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col size="6" size-md="6" size-lg="6">
          <ion-list>
            <ion-list-header>
              <ion-label>From Account</ion-label>
            </ion-list-header>
            <ion-item>
              <ion-text *ngIf="transaction.from_account.family && !transaction.from_account.kiddy">
                {{ transaction.from_account.family.name }}
              </ion-text>
              <ion-text *ngIf="transaction.from_account.kiddy">
                {{ transaction.from_account.kiddy.firstname }}
                {{ transaction.from_account.kiddy.lastname }}
              </ion-text>
              <ion-text *ngIf="transaction.from_account.school">
                {{ transaction.from_account.school.name }}
              </ion-text>
              <ion-text *ngIf="transaction.from_account.personal">
                {{ transaction.from_account.personal.firstname }}
                {{ transaction.from_account.personal.lastname }}
              </ion-text>
              <ion-text *ngIf="!transaction.from_account.family && !transaction.from_account.kiddy && !transaction.from_account.school && !transaction.from_account.personal">
                {{ transaction.from_account.name }}
              </ion-text>
            </ion-item>
          </ion-list>    
        </ion-col>
        <ion-col size="6" size-md="6" size-lg="6">
          <ion-list>
            <ion-list-header>
              <ion-label>To Account</ion-label>
            </ion-list-header>
            <ion-item>
              <ion-text *ngIf="transaction.to_account.family && !transaction.to_account.kiddy">
                {{ transaction.to_account.family.name }}
              </ion-text>
              <ion-text *ngIf="transaction.to_account.kiddy">
                {{ transaction.to_account.kiddy.firstname }}
                {{ transaction.to_account.kiddy.lastname }}
              </ion-text>
              <ion-text *ngIf="transaction.to_account.school">
                {{ transaction.to_account.school.name }}
              </ion-text>
              <ion-text *ngIf="transaction.to_account.personal">
                {{ transaction.to_account.personal.firstname }}
                {{ transaction.to_account.personal.lastname }}
              </ion-text>
              <ion-text *ngIf="!transaction.to_account.family && !transaction.to_account.kiddy && !transaction.to_account.school && !transaction.to_account.personal">
                {{ transaction.to_account.name }}
              </ion-text>
            </ion-item>
          </ion-list>    
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="6" size-md="6" size-lg="6">
          <ion-list>
            <ion-list-header>
              <ion-label>Amount</ion-label>
            </ion-list-header>
            <ion-item>
              {{ transaction.from_account.country.currency.code }} {{ transaction.amount | number }}
            </ion-item>
          </ion-list>
        </ion-col>

        <ion-col size="6" size-md="6" size-lg="6">
          <ion-list>
            <ion-list-header>
              <ion-label>Status</ion-label>
            </ion-list-header>
            <ion-item>
              {{ transaction.status }}
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="transaction.status !== TransactionStatusEnum.Pending">
        <ion-col size="6" size-md="6" size-lg="6">
          <ion-list>
            <ion-list-header>
              <ion-label>Created</ion-label>
            </ion-list-header>
            <ion-item>
              {{ transaction.creation_date | date: 'longDate' }}
            </ion-item>
          </ion-list>
        </ion-col>
        <ion-col size="6" size-md="6" size-lg="6">
          <ion-list>
            <ion-list-header>
              <ion-label>Updated</ion-label>
            </ion-list-header>
            <ion-item>
              {{ transaction.updated_on | date: 'longDate' }}
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>

    </ion-grid>
  </ion-card-content>
</ion-card>
