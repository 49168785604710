import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchoolCardComponent } from './card/card.component';

@NgModule({
  declarations: [
    SchoolCardComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    SchoolCardComponent,
  ],
})
export class SchoolModule { }
