<ion-app>
  <ion-split-pane contentId="main-content" [disabled]="!isLoggedIn">
    <ion-menu contentId="main-content" type="overlay" [disabled]="!isLoggedIn" class="menu" >
      <ion-content>

        <ion-grid class="ion-hide-lg-down">
          <ion-row class="ion-justify-content-center">
            <ion-col size="8">
              <ion-img
                src="{{ staticBaseUrl + '/kiddy-cash-logo.svg' }}"
                class="light" 
                height="40" 
                alt="logo" 
              ></ion-img>
              <ion-img
                src="{{ staticBaseUrl + '/kiddy-cash-white.svg' }}"
                class="dark" 
                height="40" 
                alt="logo" 
              ></ion-img>
            </ion-col>
          </ion-row>

          <ion-row class="ion-justify-content-center">
            <ion-col size="auto">
              <ion-list>
                <ion-menu-toggle auto-hide="false">
                  <div class="list-background">
                    <ion-item routerDirection="root" routerLink="/dashboard" lines="none" detail="false">
                      <ion-icon name="home" class="mr-20"></ion-icon>
                      <ion-label>Home</ion-label>
                    </ion-item>

                    <ion-item routerDirection="root" routerLink="/school" lines="none" detail="false">
                      <ion-icon name="school" class="mr-20"></ion-icon>
                      <ion-label>Schools</ion-label>
                    </ion-item>

                    <ion-item routerDirection="root" routerLink="/school/create" lines="none" detail="false">
                      <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                      <ion-icon name="add-circle" class="mr-20"></ion-icon>
                      <ion-label>Create</ion-label>
                    </ion-item>

                    <ion-item routerDirection="root" routerLink="/school/account" lines="none" detail="false">
                      <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                      <ion-icon name="wallet" class="mr-20"></ion-icon>
                      <ion-label>Accounts</ion-label>
                    </ion-item>

                    <ion-item routerDirection="root" routerLink="/school/transaction" lines="none" detail="false">
                      <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                      <ion-icon name="cash" class="mr-20"></ion-icon>
                      <ion-label>Transactions</ion-label>
                    </ion-item>

                    <ion-item routerDirection="root" routerLink="/profile" lines="none" detail="false">
                      <ion-icon name="person-circle" class="mr-20"></ion-icon>
                      <ion-label>Profile</ion-label>
                    </ion-item>
          
                    <ion-item routerDirection="root" routerLink="/notifications" lines="none" detail="false">
                      <ion-icon name="notifications" class="mr-20"></ion-icon>
                      <ion-label>Notifications</ion-label>
                    </ion-item>

                    <ion-item (click)="openHelpCenter()" lines="none" detail="false">
                      <ion-icon name="help-circle" class="mr-20"></ion-icon>
                      <ion-label>Help Center</ion-label>
                    </ion-item>

                    <ion-item lines="none" detail="false" (click)="logout()">
                      <ion-icon name="log-out" class="mr-20"></ion-icon>
                      <ion-label>Logout</ion-label>
                    </ion-item>      
                  </div>
                </ion-menu-toggle>
              </ion-list>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid class="ion-hide-lg-up">
          <ion-row class="ion-justify-content-center">
            <ion-col size="auto">
              <ion-menu-toggle auto-hide="false">
                <ion-item routerDirection="root" routerLink="/profile">
                  <ion-avatar slot="start">
                    <img alt="avatar" src="{{ user?.profile?.avatarversion ? ( staticBaseUrl + '/profiles/' + user?.profile?.id + '/avatars/' + user?.profile?.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/avatar.svg') }}"/>
                  </ion-avatar>
                  <ion-label>{{ user?.profile?.firstname }} {{ user?.profile?.lastname }}</ion-label>
                </ion-item>  
              </ion-menu-toggle>
            </ion-col>
          </ion-row>

          <ion-row class="ion-justify-content-center">
            <ion-col size="auto">
              <ion-list>
                <ion-menu-toggle auto-hide="false">
                  <div>
                    <ion-item routerDirection="root" routerLink="/dashboard" lines="none" detail="false">
                      <ion-icon name="home" class="mr-20"></ion-icon>
                      <ion-label>Home</ion-label>
                    </ion-item>

                    <ion-item routerDirection="root" routerLink="/school" lines="none" detail="false">
                      <ion-icon name="school" class="mr-20"></ion-icon>
                      <ion-label>Schools</ion-label>
                    </ion-item>

                    <ion-item routerDirection="root" routerLink="/school/create" lines="none" detail="false">
                      <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                      <ion-icon name="add-circle" class="mr-20"></ion-icon>
                      <ion-label>Create</ion-label>
                    </ion-item>

                    <ion-item routerDirection="root" routerLink="/school/account" lines="none" detail="false">
                      <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                      <ion-icon name="wallet" class="mr-20"></ion-icon>
                      <ion-label>Accounts</ion-label>
                    </ion-item>

                    <ion-item routerDirection="root" routerLink="/school/transaction" lines="none" detail="false">
                      <ion-icon name="chevron-forward-outline" slot="start" class="mr-20"></ion-icon>
                      <ion-icon name="cash" class="mr-20"></ion-icon>
                      <ion-label>Transactions</ion-label>
                    </ion-item>
  
                    <ion-item routerDirection="root" routerLink="/profile" lines="none" detail="false">
                      <ion-icon name="person-circle" class="mr-20"></ion-icon>
                      <ion-label>Profile</ion-label>
                    </ion-item>
          
                    <ion-item routerDirection="root" routerLink="/notifications" lines="none" detail="false">
                      <ion-icon name="notifications" class="mr-20"></ion-icon>
                      <ion-label>Notifications</ion-label>
                    </ion-item>

                    <ion-item (click)="openHelpCenter()" lines="none" detail="false">
                      <ion-icon name="help-circle" class="mr-20"></ion-icon>
                      <ion-label>Help Center</ion-label>
                    </ion-item>    
                  </div>
                </ion-menu-toggle>
              </ion-list>
            </ion-col>
          </ion-row>
        </ion-grid>

      </ion-content>

      <ion-footer class="ion-hide-lg-down list-background">
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col size="auto">
              <ion-menu-toggle auto-hide="false">
                <ion-item routerDirection="root" routerLink="/profile" lines="none" detail="false">
                  <ion-avatar slot="start">
                    <img alt="avatar" src="{{ user?.profile?.avatarversion ? ( staticBaseUrl + '/profiles/' + user?.profile?.id + '/avatars/' + user?.profile?.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/avatar.svg') }}"/>
                  </ion-avatar>
                  <ion-label>{{ user?.profile?.firstname }} {{ user?.profile?.lastname }}</ion-label>
                </ion-item>  
              </ion-menu-toggle>
            </ion-col>
          </ion-row>  
        </ion-grid>
      </ion-footer>

      <ion-footer class="ion-hide-lg-up list-background">
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col size="10">
              <ion-list>
                <ion-menu-toggle auto-hide="false">
                  <div class="list-background">
                    <ion-item lines="none" detail="false" (click)="logout()">
                      <ion-icon name="log-out" class="mr-20"></ion-icon>
                      <ion-label>Logout</ion-label>
                    </ion-item>
                  </div>
                </ion-menu-toggle>  
              </ion-list>
            </ion-col>
          </ion-row>  
        </ion-grid>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content" routerDirection="root"></ion-router-outlet>
  </ion-split-pane>
</ion-app>