import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {
  constructor(private http: HttpClient) { }

  // GET Requests
  getAllSchools() {
    return this.http.get<any>(`${environment.apiBaseUrl}/school`);
  }

  getSchoolsIds() {
    return this.http.get<any>(`${environment.apiBaseUrl}/school/ids`);
  }

  getSchoolsAccounts() {
    return this.http.get<any>(`${environment.apiBaseUrl}/school/account`);
  }

  getSchoolsTransactions() {
    return this.http.get<any>(`${environment.apiBaseUrl}/school/transaction`);
  }

  getSchool(school_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/school/${school_id}`);
  }

  getSchoolAccounts(school_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/school/${school_id}/account`);
  }

  getSchoolTransactions(school_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/school/${school_id}/transaction`);
  }

  getSchoolTransactionCodes(school_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/school/${school_id}/transaction-code`);
  }

  getCountryIntegrationForSchool(school_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/school/${school_id}/country_integration`);
  }

  getKyb(school_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/school/${school_id}/know-your-school`);
  }

  getSchoolAccount(school_id: string, account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/school/${school_id}/account/${account_id}`);
  }

  getSchoolAccountTransactions(school_id: string, account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/school/${school_id}/account/${account_id}/transaction`);
  }

  getActiveSchoolAccountIntegration(school_id: string, account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/school/${school_id}/account/${account_id}/integration`);
  }

  getCountryIntegrationForSchoolAccount(school_id: string, account_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/school/${school_id}/account/${account_id}/country_integration`);
  }

  getSchoolAccountTransaction(school_id: string, account_id: string, transaction_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/school/${school_id}/account/${account_id}/transaction/${transaction_id}`);
  }

  getSchoolTransaction(school_id: string, transaction_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/school/${school_id}/transaction/${transaction_id}`);
  }

  getSchoolTransactionCode(school_id: string, transaction_code_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/school/${school_id}/transaction-code/${transaction_code_id}`);
  }


  // POST Requests
  createSchool(data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/school`, data);
  }

  createSchoolAccount(school_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/school/${school_id}/account`, data);
  }

  createSchoolTransactionCode(school_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/school/${school_id}/transaction-code`, data);
  }

  createSchoolAccountIntegration(school_id: string, account_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/school/${school_id}/account/${account_id}/integration`, data);
  }

  withdrawFromSchoolAccount(school_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/school/${school_id}/withdraw`, data);
  }

  transactionForSchoolAccount(school_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/school/${school_id}/transaction`, data);
  }

  addSchoolManagers(school_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/school/${school_id}/managers`, data);
  }

  //PUT Requests
  updateSchool(school_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/school/${school_id}`, data);
  }

  updateSchoolAccount(school_id: string, school_account_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/school/${school_id}/account/${school_account_id}`, data);
  }

  uploadKyb(school_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/school/${school_id}/know-your-school`, data);
  }

  updateSchoolTransactionCode(school_id: string, transaction_code_id: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/school/${school_id}/transaction-code/${transaction_code_id}`, data);
  }

  // Delete Requests
  closeSchoolAccount(school_id: string, account_id: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/school/${school_id}/account/${account_id}`);
  }

  removeSchoolManagers(school_id: string, data: any) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/school/${school_id}/managers`, {
      body: data
    });
  }

}
