import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Router, RouterModule } from '@angular/router';
import { AuthService, CapacitorService, FirebaseService, PushNotificationService } from './services';
import { SchoolUserInterface } from '@kiddy-cash/common';
import { AlertController, ModalController } from '@ionic/angular';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { DeviceInfo } from '@capacitor/device';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { UpdateComponent } from './components/mobile-app/update/update.component';
import { SharedModule } from './shared/shared.module';
import { CommonModule } from '@angular/common';
import { MaskitoDirective, MaskitoPipe } from '@maskito/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    IonicModule, 
    CommonModule,
    RouterModule,
    SharedModule,
    MaskitoDirective, 
    MaskitoPipe,
  ],
})
export class AppComponent {
  user: SchoolUserInterface | null | undefined;
  isLoggedIn: boolean = false;
  staticBaseUrl: string =  environment.staticBaseUrl;
  statusBarHeight: number = 0;
  deviceInfo: DeviceInfo | undefined;
  private modal!: HTMLIonModalElement;

  constructor(
    private authService: AuthService, 
    private pushNotificationService: PushNotificationService,
    private router: Router,
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private firebaseService: FirebaseService,
    private capacitorService: CapacitorService,
    ) {}

  async ngOnInit() {
    this.authService._$user.subscribe((user: SchoolUserInterface | null | undefined) => {
      this.user = user;
      user ? this.isLoggedIn = true : this.isLoggedIn = false;
    });
    this.pushNotificationService.setUp()
    this.firebaseService.setUp()

    await this.setUpModal();

    this.capacitorService._$deviceInfo.subscribe(async (deviceInfo: DeviceInfo | undefined) => {
      this.deviceInfo = deviceInfo;
      if(deviceInfo?.platform === 'ios' || deviceInfo?.platform === 'android') {
        if(await this.isPlayStoreAppUpdateAvailable()) this.askToUpdateApp(); // Play Store Updates
      }
    });

    App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
      console.log('App opened with URL:', data);
      const url = new URL(data.url);
      const path = url.pathname;
      const search = url.search;
      search ? this.router.navigateByUrl(path + search) : this.router.navigateByUrl(path);
    });
  }

  private async setUpModal() {
    this.modal = await this.modalCtrl.create({
      component: UpdateComponent,
      canDismiss: false,
      cssClass: 'width-100-height-100'
    });

    return this.modal
  }

  isPlayStoreAppUpdateAvailable = async () : Promise<boolean> => {
    const result = await AppUpdate.getAppUpdateInfo();
    return result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE;
  };

  async askToUpdateApp() {
    this.openModal()
  }

  async logout() {
    const alert = await this.alertController.create({
      header: 'Logout',
      subHeader: 'Are you sure?',
      message: 'Logging out means you will have to log in again!',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => { 

          }
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: async () => { 
            await this.pushNotificationService.unregisterToken()
            await this.authService.logout();
            this.router.navigateByUrl('/')
          }
        }
      ]
    });

    await alert.present();
  }

  openModal() {
    if(this.modal?.isOpen) return;
    this.modal.canDismiss = false;
    this.modal.present();
  }

  dismissUpdateModal() {
    if(!this.modal?.isOpen) return;
    this.modal.dismiss();
  }

  openHelpCenter() {
    window.open("https://support.kiddy.cash/", "KiddyCashSupport");
  }

}
