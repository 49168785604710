import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GetResult, Preferences } from '@capacitor/preferences';
import { BehaviorSubject } from 'rxjs';
import { jwtDecode } from "jwt-decode";
import { SchoolUserInterface } from '@kiddy-cash/common';
import { Badge } from '@capawesome/capacitor-badge';
import { AlertController, ModalController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public _$user = new BehaviorSubject<SchoolUserInterface | null | undefined>(undefined);
  public _$token = new BehaviorSubject<string | null | undefined>(undefined);

  constructor(
    private http: HttpClient,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
  ) { }

  generateOTP(data: any) {
    return this.http.post<any>(`${environment.authBaseUrl}/register/school/email-get-otp`, data);
  }

  verifyOTP(data: any) {
    return this.http.post<any>(`${environment.authBaseUrl}/register/school/email-validate-otp`, data);
  }

  registerUser(registerUserData: any) {
    return this.http.post(`${environment.authBaseUrl}/register/school/email`, registerUserData);;
  }

  generateLoginOTP(data: any) {
    return this.http.post<any>(`${environment.authBaseUrl}/login/school/email-get-otp`, data);
  }

  validateLoginOTP(data: any) {
    return this.http.post<any>(`${environment.authBaseUrl}/login/school/email-validate-otp`, data);
  }

  requestEmailOTP(email: string) {
    return this.http.post(`${environment.authBaseUrl}/user/request-email-otp`, 
      {
        email: email
      }
    );
  }

  verifyEmailOTP(email: string, code: string) {
    return this.http.post(`${environment.authBaseUrl}/user/verify-email-otp`, 
      {
        email: email,
        code: code,        
      }
    );
  }

  requestAccountPhoneOTP(phone: string) {
    return this.http.post(`${environment.authBaseUrl}/account/school-user/add-phone/get-otp`, 
      {
        phone: phone
      }
    );
  }

  verifyAccountPhoneOTP(phone: string, code: string) {
    return this.http.post(`${environment.authBaseUrl}/account/school-user/add-phone/validate-otp`, 
      {
        phone: phone,
        code: code,        
      }
    );
  }

  requestAccountEmailOTP(email: string) {
    return this.http.post(`${environment.authBaseUrl}/account/school-user/add-email/get-otp`, 
      {
        email: email
      }
    );
  }

  verifyAccountEmailOTP(email: string, code: string) {
    return this.http.post(`${environment.authBaseUrl}/account/school-user/add-email/validate-otp`, 
      {
        email: email,
        code: code,        
      }
    );
  }

  requestPasswordResetEmailOTP(email: string) {
    return this.http.post(`${environment.authBaseUrl}/password/school/email-get-otp`, 
      {
        email: email
      }
    );
  }

  verifyPasswordResetEmailOTP(email: string, code: number) {
    return this.http.post(`${environment.authBaseUrl}/password/school/email-validate-otp`, 
      {
        email: email,
        code: code,        
      }
    );
  }

  confirmPasswordResetEmailOTP(email: string, code: number, password: string) {
    return this.http.post(`${environment.authBaseUrl}/password/school/email-confirm-otp`, 
      {
        email: email,
        code: code,
        password: password,
      }
    );
  }

  async getAuthToken() {
    const ret:any = await Preferences.get({ key: 'access_token' });
    const token = ret.value;
    return token
  }

  async logout() {
    await Preferences.remove({key: 'access_token'})
    await Preferences.remove({key: 'fcm_token'})
    await Preferences.remove({key: '__lastActive'})
    this._$user.next(null);
    this._$token.next(null);
    if(Capacitor.isNativePlatform()) await Badge.clear();

    // dismiss any modal if present
    const topModal = await this.modalCtrl.getTop();
    if(topModal) topModal.canDismiss = true;
    if(topModal) await this.modalCtrl.dismiss();

    // dismiss any topAlert if present
    const topAlert = await this.alertCtrl.getTop();
    if(topAlert) await this.alertCtrl.dismiss();
  }

  async checkStoredUser() {
    const storedToken: GetResult = await Preferences.get({ key: 'access_token' });
    const token = storedToken.value;    

    if(!token) {
      this.setUser(null);
      return;
    }
    this.setToken(token);

    const decodedUser: any = jwtDecode(token);
    const user: SchoolUserInterface = decodedUser.school_user;

    this.setUser(user);
  }

  setUser(user: SchoolUserInterface | null | undefined) {
    this._$user.next(user);
  }

  setToken(token: string) {
    this._$token.next(token);
  }

  async storeToken(token: string) {
    await Preferences.set({
      key: 'access_token',
      value: token
    });
    this.setToken(token);
  }

  async decodeAndStoreToken(token: string) {
    await this.storeToken(token);

    const decodedUser: any = jwtDecode(token);
    const user: SchoolUserInterface = decodedUser.school_user;

    this.setUser(user);
  }

}
