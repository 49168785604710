import { Component, OnInit } from '@angular/core';
import { Badge } from '@capawesome/capacitor-badge';
import { SchoolUserInterface } from '@kiddy-cash/common';
import { AuthService, NotificationService, PushNotificationService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent  implements OnInit {
  school_user: SchoolUserInterface | null | undefined;
  staticBaseUrl: string =  environment.staticBaseUrl;
  unreadNotifications: number = 0;

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private pushNotificationService: PushNotificationService,
  ) { }

  ngOnInit() {
    this.authService._$user.subscribe((school_user: SchoolUserInterface | null | undefined) => {
      this.school_user = school_user;
      if(school_user){
        this.notificationService.getUnreadNotifications().subscribe((res: any) => {
          this.unreadNotifications = res.data.unreadNotifications;
          if(this.pushNotificationService.isBadgePermissionsGranted()){
            this.unreadNotifications ? this.setBadge(this.unreadNotifications) : this.clearBadge();
          }
        },
        (err: any) => {

        })
      }
    });
  }
  
  async setBadge(count: number) {
    await Badge.set({ count });
  };
  
  async clearBadge() {
    await Badge.clear();
  };

}
